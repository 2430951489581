import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const dev: IPartnerAppSettings  = {
  cdnBaseUrl: 'http://localhost:5003',
  serviceEndPoints: [
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://psdicentraapi-dev.azurewebsites.net',
    }
  ],
};
